import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';
const BusReport: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  return (
    <div className="p-6">
      <h1 className="text-white text-3xl font-bold mb-6">Bus Reports</h1>
      {/* Add reports components here */}
      <p className='text-white'>Bus Reports section coming soon!</p>
    </div>
  );
};

export default BusReport;
