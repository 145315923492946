import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from '../firebase-config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface Ad {
  id: string;
  title: string;
  desc: string;
  fimg: string;
  url: string;
}

const Ads: React.FC = () => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingAd, setEditingAd] = useState<Ad | null>(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Omit<Ad, 'id'>>({
    title: '',
    desc: '',
    fimg: '',
    url: ''
  });
  useEffect(() => {
    // Check authentication status
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const adsCollectionRef = collection(db, 'ads');
        const adsSnapshot = await getDocs(adsCollectionRef);
        const adsList = adsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Ad[];
        setAds(adsList);
      } catch (error) {
        console.error('Error fetching ads:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAds();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = async () => {
    try {
      await addDoc(collection(db, 'ads'), formData);
      toast.success('Ad added successfully!');
      setFormData({ title: '', desc: '', fimg: '', url: '' });
      setLoading(true);
      setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error adding ad:', error);
      toast.error('Error adding ad.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (editingAd) {
      try {
        const adDocRef = doc(db, 'ads', editingAd.id);
        await updateDoc(adDocRef, formData);
        toast.success('Ad updated successfully!');
        setEditingAd(null);
        setFormData({ title: '', desc: '', fimg: '', url: '' });
        setLoading(true);
        setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
      } catch (error) {
        console.error('Error updating ad:', error);
        toast.error('Error updating ad.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (ad: Ad) => {
    setEditingAd(ad);
    setFormData({
      title: ad.title,
      desc: ad.desc,
      fimg: ad.fimg,
      url: ad.url
    });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'ads', id));
      toast.success('Ad deleted successfully!');
      setLoading(true);
      setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error deleting ad:', error);
      toast.error('Error deleting ad.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <h1 className="text-white text-3xl font-bold mb-6">Advertisement Management</h1>
      <div className="mb-6">
        <h2 className="text-white text-xl font-semibold mb-2">{editingAd ? 'Edit Ad' : 'Add New Ad'}</h2>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Title"
          className="border p-2 mb-2 w-full"
        />
        <textarea
          name="desc"
          value={formData.desc}
          onChange={handleChange}
          placeholder="Description"
          className="border p-2 mb-2 w-full"
        />
        <input
          type="text"
          name="fimg"
          value={formData.fimg}
          onChange={handleChange}
          placeholder="Image URL"
          className="border p-2 mb-2 w-full"
        />
        <input
          type="text"
          name="url"
          value={formData.url}
          onChange={handleChange}
          placeholder="URL"
          className="border p-2 mb-2 w-full"
        />
        <button
          onClick={editingAd ? handleUpdate : handleAdd}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {editingAd ? 'Update Ad' : 'Add Ad'}
        </button>
      </div>
      {loading ? (
        <p className='text-white'>Loading ads...</p>
      ) : (
        <div className="flex flex-wrap gap-6">
          {ads.map((ad) => (
            <div key={ad.id} className="p-6 bg-white shadow-base rounded-md max-w-lg ">
              <h2 className="text-lg font-semibold mb-2">{ad.title}</h2>
              <img src={ad.fimg} alt={ad.title} className="mb-2 rounded-md w-full h-24 object-cover" />
              <p className="text-sm">{ad.desc}</p>
              <a href={ad.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">View</a>
              <div className="mt-2">
                <button
                  onClick={() => handleEdit(ad)}
                  className="bg-yellow-500 text-white p-2 rounded mr-2 text-sm"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(ad.id)}
                  className="bg-red-500 text-white p-2 rounded text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Ads;
