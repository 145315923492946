import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from '../firebase-config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface News {
  id: string;
  title: string;
  desc: string;
  fimg: string;
  url: string;
}

const News: React.FC = () => {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingNews, setEditingNews] = useState<News | null>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Omit<News, 'id'>>({
    title: '',
    desc: '',
    fimg: '',
    url: ''
  });
  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsCollectionRef = collection(db, 'news');
        const newsSnapshot = await getDocs(newsCollectionRef);
        const newsList = newsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as News[];
        setNews(newsList);
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = async () => {
    try {
      await addDoc(collection(db, 'news'), formData);
      toast.success('News added successfully!');
      setFormData({ title: '', desc: '', fimg: '', url: '' });
      setFormVisible(false);
      setLoading(true);
      setTimeout(() => window.location.reload(), 1000); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error adding news:', error);
      toast.error('Error adding news.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (editingNews) {
      try {
        const newsDocRef = doc(db, 'news', editingNews.id);
        await updateDoc(newsDocRef, formData);
        toast.success('News updated successfully!');
        setEditingNews(null);
        setFormData({ title: '', desc: '', fimg: '', url: '' });
        setFormVisible(false);
        setLoading(true);
        setTimeout(() => window.location.reload(), 1000); // Refresh page after 1.5 seconds
      } catch (error) {
        console.error('Error updating news:', error);
        toast.error('Error updating news.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (newsItem: News) => {
    setEditingNews(newsItem);
    setFormData({
      title: newsItem.title,
      desc: newsItem.desc,
      fimg: newsItem.fimg,
      url: newsItem.url
    });
    setFormVisible(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'news', id));
      toast.success('News deleted successfully!');
      setLoading(true);
      setTimeout(() => window.location.reload(), 1000); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error deleting news:', error);
      toast.error('Error deleting news.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <h1 className="text-white text-3xl font-bold mb-6">News Management</h1>
      <button
        onClick={() => setFormVisible(!formVisible)}
        className="bg-blue-500 text-white p-2 rounded mb-4"
      >
        {formVisible ? 'Hide Form' : 'Add New News'}
      </button>

      {formVisible ? (
        <div className="mb-6">
          <h2 className="text-white text-xl font-semibold mb-2">{editingNews ? 'Edit News' : 'Add New News'}</h2>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Title"
            className="border p-2 mb-2 w-full"
          />
          <textarea
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            placeholder="Description"
            className="border p-2 mb-2 w-full"
          />
          <input
            type="text"
            name="fimg"
            value={formData.fimg}
            onChange={handleChange}
            placeholder="Image URL"
            className="border p-2 mb-2 w-full"
          />
          <input
            type="text"
            name="url"
            value={formData.url}
            onChange={handleChange}
            placeholder="URL"
            className="border p-2 mb-2 w-full"
          />
          <button
            onClick={editingNews ? handleUpdate : handleAdd}
            className="bg-blue-500 text-white p-2 rounded"
          >
            {editingNews ? 'Update News' : 'Add News'}
          </button>
        </div>
      ) : (
        loading ? (
          <p className="text-white">Loading news...</p>
        ) : (
          <div className="flex flex-wrap gap-6 mb-4">
            {news.map((newsItem) => (
              <div key={newsItem.id} className="p-6 bg-white shadow-md rounded-md max-w-xs max-h-30">
                <h2 className="text-lg font-semibold mb-2">{newsItem.title}</h2>
                <img src={newsItem.fimg} alt={newsItem.title} className="mb-2 rounded-md w-full h-24 object-cover" />
                <p className="text-sm">{newsItem.desc}</p>
                <a href={newsItem.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">View</a>
                <div className="mt-2">
                  <button
                    onClick={() => handleEdit(newsItem)}
                    className="bg-yellow-500 text-white p-2 rounded mr-2 text-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(newsItem.id)}
                    className="bg-red-500 text-white p-2 rounded text-sm"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default News;
