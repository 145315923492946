import React, { useEffect, useState } from 'react';
import { collection, getDocs } from '../firebase-config';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';
import { db } from "../firebase-config";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

const Dashboard: React.FC = () => {
  const [adsCount, setAdsCount] = useState<number>(0);
  const [eventsCount, setEventsCount] = useState<number>(0);
  const [newsCount, setNewsCount] = useState<number>(0);
  const [busesCount, setBusesCount] = useState<number>(0);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const adsCollection = collection(db, 'ads');
        const adsSnapshot = await getDocs(adsCollection);
        setAdsCount(adsSnapshot.size);

        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        setEventsCount(eventsSnapshot.size);

        const newsCollection = collection(db, 'news');
        const newsSnapshot = await getDocs(newsCollection);
        setNewsCount(newsSnapshot.size);

        const busesCollection = collection(db, 'BusList');
        const busesSnapshot = await getDocs(busesCollection);
        setBusesCount(busesSnapshot.size);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    if (!isAuthenticated) {
      // Redirect to login if not authenticated
      navigate('/login');
    } else {
      fetchCounts();
    }
  }, [isAuthenticated, navigate]);

  // Data for Bar Chart
  const barData = {
    labels: ['Ads', 'Events', 'News', 'Buses'],
    datasets: [
      {
        label: 'Counts',
        data: [adsCount, eventsCount, newsCount, busesCount],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Data for Pie Chart
  const pieData = {
    labels: ['Ads', 'Events', 'News', 'Buses'],
    datasets: [
      {
        data: [adsCount, eventsCount, newsCount, busesCount],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <div className="p-6 h-full ">
      <h1 className="text-white text-3xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="p-4 bg-white shadow-md rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Counts</h2>
          <Bar data={barData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
        </div>
        <div className="p-4 bg-white shadow-md rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Distribution</h2>
          <Pie data={pieData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
        </div>
        <div className="p-6 bg-white shadow-md rounded-lg flex flex-col gap-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Summary</h2>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between p-4 bg-gray-100 rounded-md shadow-sm">
              <span className="text-lg font-medium text-gray-700">Total Ads:</span>
              <span className="text-xl font-semibold text-gray-900">{adsCount}</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-100 rounded-md shadow-sm">
              <span className="text-lg font-medium text-gray-700">Total Events:</span>
              <span className="text-xl font-semibold text-gray-900">{eventsCount}</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-100 rounded-md shadow-sm">
              <span className="text-lg font-medium text-gray-700">Total News:</span>
              <span className="text-xl font-semibold text-gray-900">{newsCount}</span>
            </div>
            <div className="flex items-center justify-between p-4 bg-gray-100 rounded-md shadow-sm">
              <span className="text-lg font-medium text-gray-700">Total Buses:</span>
              <span className="text-xl font-semibold text-gray-900">{busesCount}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
