import React, { useState, useEffect } from 'react';
import { collection, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from '../firebase-config'; // Import Firestore configuration
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface Lecturer {
  profilePic: string;
  LecturerName: string;
  LecturerCode: string;
  SectionName: string[];
}

interface User {
  id: string;
  imageURL: string;
  matricNo: string;
  name: string;
  username: string;
}

const AssignUsersPage: React.FC = () => {
  const [lecturers, setLecturers] = useState<Lecturer[]>([]);
  const [selectedLecturerCode, setSelectedLecturerCode] = useState<string | null>(null);
  const [selectedSectionName, setSelectedSectionName] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const usersPerPage = 6;
  const [assignedUsers, setAssignedUsers] = useState<{ [key: string]: string[] }>({}); // Holds assigned users per section
  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchLecturers = async () => {
      try {
        const lecturersRef = collection(db, 'golecturer');
        const lecturersSnapshot = await getDocs(lecturersRef);
        const lecturersList = lecturersSnapshot.docs.map(doc => doc.data() as Lecturer);
        setLecturers(lecturersList);
      } catch (error) {
        console.error("Error fetching lecturers: ", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const usersList = usersSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            imageURL: data.imageURL || '',
            matricNo: data.matricNo || '',
            name: data.name || '',
            username: data.username || ''
          } as User;
        });
        setUsers(usersList);
        setFilteredUsers(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLecturers();
    fetchUsers();
  }, []);

  useEffect(() => {
    const searchLower = search.toLowerCase();
    const result = users.filter(user =>
      user.name.toLowerCase().includes(searchLower) ||
      user.username.toLowerCase().includes(searchLower) ||
      user.matricNo.toLowerCase().includes(searchLower)
    );
    setFilteredUsers(result);
    setCurrentPage(1);
  }, [search, users]);

  useEffect(() => {
    const fetchAssignedUsers = async () => {
      if (selectedLecturerCode && selectedSectionName) {
        try {
          const gosectionsRef = collection(db, 'gosections');
          const docRef = doc(gosectionsRef, `${selectedLecturerCode}_${selectedSectionName}`);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setAssignedUsers(prev => ({
              ...prev,
              [selectedSectionName]: data.users ? data.users.map((user: { id: string }) => user.id) : []
            }));
          }
        } catch (error) {
          console.error("Error fetching assigned users: ", error);
        }
      }
    };

    fetchAssignedUsers();
  }, [selectedLecturerCode, selectedSectionName]);

  const handleLecturerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLecturerCode(e.target.value);
    setSelectedSectionName(null); // Reset section on lecturer change
  };

  const handleSectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSectionName(e.target.value);
  };

  const handleUserSelect = async (userId: string) => {
    if (selectedSectionName) {
      setSelectedUsers(prevState => {
        const isSelected = prevState.includes(userId);
        const updatedState = isSelected 
          ? prevState.filter(id => id !== userId) // Deselect
          : [...prevState, userId]; // Select
  
        // Update the assignedUsers state
        setAssignedUsers(prev => ({
          ...prev,
          [selectedSectionName]: isSelected
            ? prev[selectedSectionName]?.filter(id => id !== userId) || []
            : [...(prev[selectedSectionName] || []), userId]
        }));
  
        // Update Firestore
        const updateFirestore = async () => {
          try {
            const gosectionsRef = collection(db, 'gosections');
            const docRef = doc(gosectionsRef, `${selectedLecturerCode}_${selectedSectionName}`);
  
            // Get current section data
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              const existingUsers = data.users || [];
  
              // Update user list
              const updatedUsers = isSelected
                ? existingUsers.filter((user: { id: string }) => user.id !== userId)
                : [...existingUsers, { id: userId, name: users.find(u => u.id === userId)?.name || '' }];
  
              await setDoc(docRef, { users: updatedUsers }, { merge: true });
              
            }
          } catch (error) {
            console.error("Error updating Firestore: ", error);
            toast.error("Error updating Firestore. Please try again.");
          }
        };
  
        updateFirestore();
  
        return updatedState;
      });
    }
  };
  
  

  const handleAssignUsers = async () => {
    if (!selectedLecturerCode || !selectedSectionName) {
      toast.error("Please select a lecturer and section.");
      return;
    }

    try {
      const gosectionsRef = collection(db, 'gosections');
      const usersWithDetails = selectedUsers.map(userId => {
        const user = users.find(u => u.id === userId);
        return {
          id: user?.id || '',
          name: user?.name || ''
        };
      });

      const docRef = doc(gosectionsRef, `${selectedLecturerCode}_${selectedSectionName}`);
      const docSnapshot = await getDoc(docRef);
      let newSectionDoc: any = {
        lecturerCode: selectedLecturerCode,
        sectionName: selectedSectionName,
        users: usersWithDetails,
      };

      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        const existingUsers = existingData.users || [];

        const updatedUsers = [
          ...existingUsers,
          ...usersWithDetails.filter(user => 
            !existingUsers.some((existingUser: { id: string }) => existingUser.id === user.id)
          )
        ];

        newSectionDoc = {
          ...existingData,
          users: updatedUsers,
        };
      }

      await setDoc(docRef, newSectionDoc, { merge: true });
      toast.success("Users assigned successfully!");

      // Refresh the page after 4 seconds
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (error) {
      console.error("Error assigning users: ", error);
      toast.error("Error assigning users. Please try again.");
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen py-8 px-4">
      <ToastContainer />
      <div className="container mx-auto">
        <h1 className="text-3xl text-white font-bold text-center mb-8">Go Section Registration</h1>

        <div className="flex justify-center mb-6">
          <input
            type="text"
            placeholder="Search by Name, Username, or Matric No"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="px-4 py-2 border rounded-lg w-full max-w-md shadow-sm focus:ring focus:ring-indigo-500 focus:outline-none"
          />
        </div>

        <div className="mb-6">
          <div className="flex mb-4">
            <select
              value={selectedLecturerCode || ''}
              onChange={handleLecturerChange}
              className="px-4 py-2 border rounded-lg shadow-sm mr-4"
            >
              <option value="">Select Lecturer</option>
              {lecturers.map(lecturer => (
                <option key={lecturer.LecturerCode} value={lecturer.LecturerCode}>
                  {lecturer.LecturerName}
                </option>
              ))}
            </select>
            <select
              value={selectedSectionName || ''}
              onChange={handleSectionChange}
              className="px-4 py-2 border rounded-lg shadow-sm"
            >
              <option value="">Select Section</option>
              {selectedLecturerCode && lecturers.find(l => l.LecturerCode === selectedLecturerCode)?.SectionName.map(section => (
                <option key={section} value={section}>
                  {section}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-bold mb-4 text-white">Select Student</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {loading ? (
              <p className="text-center text-gray-500">Loading...</p>
            ) : (
              currentUsers.map(user => (
                <div key={user.matricNo} className="bg-white p-4 rounded-lg shadow-md">
                  <img
                    src={user.imageURL}
                    alt={user.name}
                    className="w-24 h-24 object-cover rounded-full mx-auto"
                  />
                  <h3 className="text-lg font-semibold">{user.name}</h3>
                  <p className="text-sm text-gray-600">{user.matricNo}</p>
                  <p className="text-sm text-gray-600">{user.username}</p>
                  <button
  onClick={() => handleUserSelect(user.id)}
  className={`mt-4 px-4 py-2 rounded-lg ${
    selectedUsers.includes(user.id) || (selectedSectionName && assignedUsers[selectedSectionName]?.includes(user.id)) ? 'bg-red-500 text-white' : 'bg-blue-500 text-white'
  }`}
>
  {(selectedUsers.includes(user.id) || (selectedSectionName && assignedUsers[selectedSectionName]?.includes(user.id))) ? 'Deselect' : 'Select'}
</button>

                </div>
              ))
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleAssignUsers}
            className="px-6 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none"
          >
            Assign Users
          </button>
        </div>

        <div className="flex justify-center mt-6">
          <nav>
            <ul className="flex space-x-2">
              {[...Array(Math.ceil(filteredUsers.length / usersPerPage))].map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => paginate(index + 1)}
                    className={`px-4 py-2 border rounded-lg ${
                      currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
                    }`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AssignUsersPage;
