import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase-config'; // Import Firestore configuration
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';
interface Lecturer {
  profilePic: string;
  LecturerName: string;
  LecturerCode: string;
  SectionName: string[];
}

const LecturersPage: React.FC = () => {
  const [lecturers, setLecturers] = useState<Lecturer[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [currentLecturer, setCurrentLecturer] = useState<Lecturer | null>(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [lecturerForm, setLecturerForm] = useState<Lecturer>({
    profilePic: '',
    LecturerName: '',
    LecturerCode: '',
    SectionName: []
  });

  const lecturersPerPage = 6;
  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchLecturers = async () => {
      setLoading(true);
      try {
        const lecturersRef = collection(db, 'golecturer');
        const lecturersSnapshot = await getDocs(query(lecturersRef, orderBy('LecturerName')));
        const lecturersList = lecturersSnapshot.docs.map(doc => doc.data() as Lecturer);
        setLecturers(lecturersList);
      } catch (error) {
        console.error("Error fetching lecturers: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLecturers();
  }, []);

  // Filter lecturers based on search
  const filteredLecturers = lecturers.filter(lecturer =>
    lecturer.LecturerName.toLowerCase().includes(search.toLowerCase()) ||
    lecturer.LecturerCode.toLowerCase().includes(search.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastLecturer = currentPage * lecturersPerPage;
  const indexOfFirstLecturer = indexOfLastLecturer - lecturersPerPage;
  const currentLecturers = filteredLecturers.slice(indexOfFirstLecturer, indexOfLastLecturer);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Handle Add or Edit modal opening
  const handleAdd = () => {
    setModalMode('add');
    setLecturerForm({
      profilePic: '',
      LecturerName: '',
      LecturerCode: '',
      SectionName: []
    });
    setIsModalOpen(true);
  };

  const handleEdit = (lecturer: Lecturer) => {
    setModalMode('edit');
    setLecturerForm(lecturer);
    setCurrentLecturer(lecturer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle form input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLecturerForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSectionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLecturerForm(prevState => ({
      ...prevState,
      SectionName: e.target.value.split(',')
    }));
  };

  // Handle Save Lecturer (Add or Edit)
  const handleSaveLecturer = async () => {
    try {
      if (modalMode === 'add') {
        // Add a new lecturer
        const newLecturerRef = doc(collection(db, 'golecturer'));
        await setDoc(newLecturerRef, { ...lecturerForm } as Record<string, any>);  // Cast to Record<string, any>
        setLecturers(prevState => [...prevState, lecturerForm]);
      } else if (modalMode === 'edit' && currentLecturer) {
        // Update the existing lecturer
        const lecturerRef = doc(db, 'golecturer', currentLecturer.LecturerCode);
        await updateDoc(lecturerRef, { ...lecturerForm } as Record<string, any>);  // Cast to Record<string, any>
        setLecturers(prevState => prevState.map(l => l.LecturerCode === currentLecturer.LecturerCode ? lecturerForm : l));
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving lecturer: ", error);
    }
  };

  return (
    <div className="min-h-screen  py-8 px-4">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-white text-center mb-8">Lecturer Management</h1>

        {/* Search Bar */}
        <div className="flex justify-center mb-6">
          <input
            type="text"
            placeholder="Search by Lecturer Name or Code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="px-4 py-2 border rounded-lg w-full max-w-md shadow-sm focus:ring focus:ring-indigo-500 focus:outline-none"
          />
        </div>

        {/* Add Button */}
        <div className="flex justify-start mb-4">
          <button
            onClick={handleAdd}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600"
          >
            Add Lecturer
          </button>
        </div>

        {/* Display Lecturers */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : (
            currentLecturers.map((lecturer, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <img
                  src={`https://xcess.iium.edu.my/packages/card/printing/camera/uploads/original/${lecturer.LecturerCode}.jpeg`}
                  alt={lecturer.LecturerName}
                  className="w-full h-40 object-cover rounded-t-lg"
                />
                <div className="mt-4">
                  <h2 className="text-lg font-bold">{lecturer.LecturerName}</h2>
                  <p className="text-gray-600">Code: {lecturer.LecturerCode}</p>
                  <p className="text-gray-600">Sections: {lecturer.SectionName.join(', ')}</p>
                </div>
                <button
                  onClick={() => handleEdit(lecturer)}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600"
                >
                  Edit
                </button>
              </div>
            ))
          )}
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-8">
          <nav>
            <ul className="inline-flex -space-x-px">
              {Array.from({ length: Math.ceil(filteredLecturers.length / lecturersPerPage) }, (_, i) => (
                <li key={i}>
                  <button
                    onClick={() => paginate(i + 1)}
                    className={`px-4 py-2 border ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} hover:bg-blue-600 hover:text-white`}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Modal for Add/Edit Lecturer */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        className="fixed z-10 inset-0 overflow-y-auto"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75"
      >
        <div className="min-h-screen flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">{modalMode === 'add' ? 'Add' : 'Edit'} Lecturer</h2>

            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Lecturer Name</label>
                <input
                  type="text"
                  name="LecturerName"
                  value={lecturerForm.LecturerName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Lecturer Code</label>
                <input
                  type="text"
                  name="LecturerCode"
                  value={lecturerForm.LecturerCode}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Sections (comma separated)</label>
                <input
                  type="text"
                  value={lecturerForm.SectionName.join(',')}
                  onChange={handleSectionNameChange}
                  className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleSaveLecturer}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg shadow hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LecturersPage;
