import React, { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface Report {
  id: string;
  reportedBy: string;
  reportText: string;
  reportedAt: string;
  reportStatus: string;
  email:string;
  remarks?: string;
  
}

const Reports: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [remarks, setRemarks] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'stureports'));
        const reportsData: Report[] = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            reportedBy: data.reportedBy,
            reportText: data.reportText,
            email :data.email,
            reportedAt: new Date(data.reportedAt.seconds * 1000).toLocaleString(),
            reportStatus: data.reportStatus,
            remarks: data.remarks || '', // Optional field
          };
        });
        setReports(reportsData);
      } catch (error) {
        console.error('Error fetching reports: ', error);
        toast.error('Error fetching reports');
      }
    };

    fetchReports();
  }, []);
  const handleUpdateToOngoing = async (report: Report) => {
    try {
      const reportDoc = doc(db, 'stureports', report.id);
      await updateDoc(reportDoc, { reportStatus: 'Ongoing' });
      toast.success('Report status updated to Ongoing');
  
      // Update the local state to reflect the change
      setReports(
        reports.map((r) =>
          r.id === report.id ? { ...r, reportStatus: 'Ongoing' } : r
        )
      );
    } catch (error) {
      console.error('Error updating report status:', error);
      toast.error('Error updating report status');
    }
  };
  
  const handleEdit = (report: Report) => {
    setSelectedReport(report);
    setRemarks(report.remarks || ''); // Set existing remarks if available
    setIsFormVisible(true);
  };

  const handleCloseReport = async () => {
    if (selectedReport) {
      if (remarks.trim() === '') {
        toast.error('Remarks are required to update the status');
        return;
      }

      try {
        const reportDoc = doc(db, 'stureports', selectedReport.id);
        let newStatus = '';

        if (selectedReport.reportStatus === 'active') {
          newStatus = 'Ongoing';
        } else if (selectedReport.reportStatus === 'Ongoing') {
          newStatus = 'Closed';
        } else if (selectedReport.reportStatus === 'Closed') {
          newStatus = 'Ongoing';
        }

        await updateDoc(reportDoc, { reportStatus: newStatus, remarks });
        toast.success(`Report status updated to ${newStatus}`);
        setIsFormVisible(false);
        setRemarks('');

        setReports(
          reports.map((r) =>
            r.id === selectedReport.id ? { ...r, reportStatus: newStatus, remarks } : r
          )
        );
      } catch (error) {
        console.error('Error updating report status:', error);
        toast.error('Error updating report status');
      }
    }
  };

  const handleDelete = async (reportId: string) => {
    try {
      await deleteDoc(doc(db, 'stureports', reportId));
      toast.success('Report deleted successfully');
      setReports(reports.filter((report) => report.id !== reportId));
    } catch (error) {
      console.error('Error deleting report:', error);
      toast.error('Error deleting report');
    }
  };

  const activeReports = reports.filter((report) => report.reportStatus === 'active');
  const ongoingReports = reports.filter((report) => report.reportStatus === 'Ongoing');
  const closedReports = reports.filter((report) => report.reportStatus === 'Closed');

  return (
    <div className="p-6">
      <h1 className="text-white text-3xl font-bold mb-1">Student Complaint / Reports</h1>
      <div className="w-full text-white mb-5">________________________</div>

      {isFormVisible && selectedReport ? (
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h2 className="text-xl mb-4">Edit Report Status</h2>
          <p className="mb-2">Matric Number: {selectedReport.reportedBy}</p>
          <p className="mb-4">Report: {selectedReport.reportText}</p>
          <label className="block mb-2 text-gray-700">Remarks:</label>
          <textarea
            className="w-full p-2 border rounded mb-4"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter remarks for this report"
          />
          <button
            onClick={handleCloseReport}
            className="bg-green-500 text-white p-2 rounded text-sm mr-2"
          >
            Update Status
          </button>
          <button
            onClick={() => setIsFormVisible(false)}
            className="bg-gray-500 text-white p-2 rounded text-sm"
          >
            Cancel
          </button>
        </div>
      ) : (
        <>
          {/* Active Reports Section */}
          <div className="mb-8">
            <h2 className="text-2xl text-center text-white font-bold mb-4">
              Received reports: {activeReports.length}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {activeReports.length > 0 ? (
                activeReports.map((report) => (
                  <div key={report.id} className="p-4 bg-white shadow-md rounded-lg">
                    <h2 className="text-xl text-center">{report.reportedBy}</h2>
                    <h2 className="text-base">Report: {report.reportText}</h2>
                    <h2 className="text-base text-gray-500">Email: {report.email}</h2>
                    <h2 className="text-base text-gray-500">Reported At: {report.reportedAt}</h2>
                    <h2 className="text-base text-gray-500">Status: {report.reportStatus}</h2>
                    {report.remarks && (
                      <p className="text-sm text-gray-700">Remarks: {report.remarks}</p>
                    )}
                    <button
                      onClick={() => handleEdit(report)}
                      className="bg-green-500 text-white p-2 mr-4 rounded text-sm mt-5"
                      disabled={report.reportStatus === 'Closed'}
                    >
                      Edit Status
                    </button>
                    <button
                      onClick={() => handleDelete(report.id)}
                      className="bg-red-500 text-white p-2 rounded text-sm mt-5"
                    >
                      Delete
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-white">No active reports available.</p>
              )}
            </div>
          </div>

          {/* Similar sections for ongoing and closed reports */}
          {/* Ongoing Reports Section */}
          <div className="mb-8">
            <h2 className="text-2xl text-center text-white font-bold mb-4">
              Ongoing reports: {ongoingReports.length}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {ongoingReports.length > 0 ? (
                ongoingReports.map((report) => (
                  <div key={report.id} className="p-4 bg-white shadow-md rounded-lg">
                    <h2 className="text-xl text-center">{report.reportedBy}</h2>
                    <h2 className="text-base">Report: {report.reportText}</h2>
                    <h2 className="text-base text-gray-500">Reported At: {report.reportedAt}</h2>
                    <h2 className="text-base text-gray-500">Status: {report.reportStatus}</h2>
                    <h2 className="text-base text-gray-500">Email: {report.email}</h2>
                    {report.remarks && (
                      <p className="text-sm text-gray-700">Remarks: {report.remarks}</p>
                    )}
                    <button
                      onClick={() => handleEdit(report)}
                      className="bg-green-500 text-white p-2 mr-4 rounded text-sm mt-5"
                      disabled={report.reportStatus === 'Closed'}
                    >
                      Edit Status
                    </button>
                    <button
                      onClick={() => handleDelete(report.id)}
                      className="bg-red-500 text-white p-2 rounded text-sm mt-5"
                    >
                      Delete
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-white">No ongoing reports available.</p>
              )}
            </div>
          </div>

          {/* Closed Reports Section */}
          <div className="mb-8">
            <h2 className="text-2xl text-center text-white font-bold mb-4">
              Closed reports: {closedReports.length}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {closedReports.length > 0 ? (
    closedReports.map((report) => (
      <div key={report.id} className="p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-xl text-center">{report.reportedBy}</h2>
        <h2 className="text-base">Report: {report.reportText}</h2>
        <h2 className="text-base text-gray-500">Reported At: {report.reportedAt}</h2>
        <h2 className="text-base text-gray-500">Status: {report.reportStatus}</h2>
        <h2 className="text-base text-gray-500">Email: {report.email}</h2>
        {report.remarks && (
          <p className="text-sm text-gray-700">Remarks: {report.remarks}</p>
        )}

        {/* Update Status Button */}
        <button
          onClick={() => handleUpdateToOngoing(report)}
          className="bg-green-500 text-white p-2 mr-4 rounded text-sm mt-5"
        >
          Reopen 
        </button>

        {/* Delete Button */}
        <button
          onClick={() => handleDelete(report.id)}
          className="bg-red-500 text-white p-2 rounded text-sm mt-5"
        >
          Delete 
        </button>
      </div>
    ))
  ) : (
    <p className="text-white">No closed reports available.</p>
  )}
</div>
          </div>
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Reports;
