import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config'; // Firebase authentication import
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User as FirebaseUser,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
} from 'firebase/firestore'; // Firestore imports

// Define the shape of the user data
interface User {
  email: string | null;
  id: string;
  displayName: string | null;
}

// Define the AuthContext type
interface AuthContextType {
  user: User | null;
  login: (username: string, password: string) => Promise<void>; // Updated login type
  logout: () => Promise<void>;
  isAuthenticated: boolean;
}

// Define props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore

  // Function to handle login with username and password
  const login = async (username: string, password: string) => {
    try {
      // Retrieve the user document from Firestore based on username
      const userDoc = await getDoc(doc(db, 'users', username)); // Assuming username is the document ID
  
      if (!userDoc.exists()) {
        throw new Error('User does not exist');
      }
  
      const userData = userDoc.data();
      const email = userData?.email;
  
      if (!email) {
        throw new Error('Email not found for the user');
      }
  
      // Check if the email is authorized before signing in
      if (!isAuthorizedEmail(email)) {
        throw new Error('Unauthorized email address');
      }
  
      // Authenticate the user
      const result = await signInWithEmailAndPassword(auth, email, password);
      const loggedInUser = mapFirebaseUser(result.user);
      setUser(loggedInUser);
      localStorage.setItem('user', JSON.stringify(loggedInUser));
      navigate('/dashboard'); // Redirect to dashboard after successful login
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Login failed: '); // Provide a user-friendly message
    }
  };
  
  // Helper function to check if an email is authorized
  const isAuthorizedEmail = (email: string) => {
    const authorizedEmails = [
      'azmi.bin.basharudin@pocketiium.com',
        'afnan.wajdi.bin.ramlee@pocketiium.com',
        'nur.ain.binti.lizam@pocketiium.com',
        'adibatunnisa\'.binti.ahmad.anuar@pocketiium.com',
        'nur.fatihah.binti.abdullah@pocketiium.com',
        'azwa.nurnisya.binti.ayub@pocketiium.com',
        
         'nur.\'aqilah.binti.zaidon@pocketiium.com',



    ];
    return authorizedEmails.includes(email);
  };
  

  // Function to log out
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('user');
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Check if user is logged in (use effect on mount for session persistence)
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const loggedInUser = mapFirebaseUser(firebaseUser);
        setUser(loggedInUser);
        localStorage.setItem('user', JSON.stringify(loggedInUser));
      } else {
        setUser(null);
        localStorage.removeItem('user');
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  // Convert Firebase user to our custom User object
  const mapFirebaseUser = (firebaseUser: FirebaseUser): User => {
    return {
      email: firebaseUser.email,
      id: firebaseUser.uid,
      displayName: firebaseUser.displayName,
    };
  };

  // Provide value to components
  const value: AuthContextType = {
    user,
    login,
    logout,
    isAuthenticated: !!user, // Helper to check if user is authenticated
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Hook to use auth context easily
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
