import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/usercontext';
import { db, doc, setDoc } from '../firebase-config';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Sidebar: React.FC = () => {
  const { logout } = useAuth();
  const [summaryData, setSummaryData] = useState<any>(null); 
  const [error, setError] = useState<string | null>(null);

  const handleLogout = async () => {
    await logout(); 
  };

  
  const fetchData = async () => {
    try {
      const response = await fetch('https://data.techforpalestine.org/api/v2/summary.json');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setSummaryData(data);
    } catch (error: any) {
      setError(error.message);
    }
  };

  // Function to handle updating Firestore
  const updateFirestore = async () => {
    if (summaryData) {
      try {
        const docRef = doc(db, 'palestine', 'summary'); // Specify the document ID (e.g., 'summary')
        await setDoc(docRef, summaryData, { merge: true }); // Merge data into the document
        toast.success('Data for Free Palestine updated!'); // Use Toastify to show success message
      } catch (error: any) {
        setError(error.message);
        toast.error(`Error: ${error.message}`); // Use Toastify to show error message
      }
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="min-h-screen w-64 bg-gray-950 text-white flex flex-col shadow-2xl">
      <div className="flex flex-col items-center p-6 mt-2 rounded-lg bg-white">
        <div className="text-black text-2xl font-semibold">Smart Verse</div>
      </div>
      <nav className="flex-1 p-4 ml-1 mb-3 mt-1 text-lg">
        <Link to="/" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Dashboard
        </Link>
        <Link to="/peak" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Peak Hours Tracker
        </Link>
        <Link to="/schedule" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Set Bus Schedule
        </Link>
        <Link to="/users" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          User Management
        </Link>
        <Link to="/bus" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Fleet Tracking
        </Link>
        <Link to="/news" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          News
        </Link>
        <Link to="/ads" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Ads
        </Link>
        <Link to="/events" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Events
        </Link>
        <Link to="/reports" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700">
          Student Complaint
        </Link>
      </nav>
      
      <div className=" p-4">
        <button
          onClick={updateFirestore}
          className="block w-full py-2.5 px-4 rounded transition duration-200 bg-green-500 hover:bg-green-700 text-white"
        >
          Save Palestine
        </button>
        {error && <div className="text-red-500 mt-2">{error}</div>} {/* Optional error display */}
      </div>

      <div className="p-4">
        <button
          onClick={handleLogout}
          className="block w-full py-2.5 px-4 rounded transition duration-200 bg-red-600 hover:bg-red-700 text-white"
        >
          Logout
        </button>
      </div>

      {/* ToastContainer to display the notifications */}
      <ToastContainer />
    </div>
  );
};

export default Sidebar;
