import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../context/usercontext'; // Your authentication context
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'; // Import desired icons

const LoginPage: React.FC = () => {
  const { login, isAuthenticated, user, logout } = useAuth();
  const [username, setUsername] = useState(''); // Correctly use username
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    if (isAuthenticated) {
      // Navigate to dashboard when user is authenticated
      navigate('/reports');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      await login(username, password); // Call the login function with username
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Login failed. Please check your credentials and try again.'); // Provide a user-friendly message
    }
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: 'url(https://tronova.azmiproductions.com/img/backy.png)' }} // Background Image
    >
      {/* Black Overlay */}
      <div className="absolute inset-0 bg-black opacity-70 z-0"></div>

      <div className="relative z-10 w-full max-w-md p-8 bg-gray-900 bg-opacity-80 rounded-lg shadow-lg">
        {/* Account Image */}
        <div className="flex justify-center mb-6">
          <img
            src="https://tronova.azmiproductions.com/img/pocketnoback.png" // Placeholder for your image
            alt="Your Account"
            className="w-24 h-24 rounded-full shadow-lg border-4 border-gray-700 object-cover"
          />
        </div>

        <h2 className="text-lg font-bold capitalize text-center text-white mb-6">Welcome Crew !</h2>

        {!isAuthenticated ? (
          <div>
            {/* Username Input */}
            <div className="relative mb-4 flex items-center">
              <FontAwesomeIcon icon={faUser} className="absolute left-3 text-gray-400" />
              <input
                type="text" // Changed from email to text for username
                placeholder="Matric Number" // Updated placeholder
                value={username} // Use username state
                onChange={(e) => setUsername(e.target.value)} // Update username state
                className="w-full pl-10 p-3 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {/* Password Input */}
            <div className="relative mb-6 flex items-center">
              <FontAwesomeIcon icon={faLock} className="absolute left-3 text-gray-400" />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full pl-10 p-3 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              onClick={handleLogin}
              className="w-full py-3 bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Login
            </button>
          </div>
        ) : (
          <div className="text-center">
            <h1 className="text-xl text-white font-semibold">Welcome, {user?.displayName || user?.email}!</h1>
            <button
              onClick={logout}
              className="mt-6 w-full py-3 bg-red-500 hover:bg-red-600 text-white font-bold rounded-lg transition duration-300 transform hover:scale-105 shadow-lg"
            >
              Log Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
