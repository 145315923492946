import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, doc, updateDoc } from '../firebase-config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';
// Modal Styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '500px',
    
  },
};

interface Stop {
  id: string;
  bus_stop_number: string;
  busname: string;
  latitude: string;
  location_name: string;
  longitude: string;
  nmea_sentence: string;
}

interface BusListItem {
  bus_stop_number: string;
}

const BusLocation: React.FC = () => {
  const [allStops, setAllStops] = useState<Stop[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentStops, setCurrentStops] = useState<BusListItem[]>([]);
  const [selectedStop, setSelectedStop] = useState<Stop | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [editedStop, setEditedStop] = useState<Partial<Stop>>({});
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    // Check authentication status
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);
  const fetchAllStops = async () => {
    try {
      const stopsCollectionRef = collection(db, 'azp_stop');
      const stopsSnapshot = await getDocs(stopsCollectionRef);
      const stopsList = stopsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<Stop, 'id'>),
      }));

      // Sort stops by bus_stop_number as numbers
      stopsList.sort((a, b) => {
        const numA = parseFloat(a.bus_stop_number);
        const numB = parseFloat(b.bus_stop_number);
        return numA - numB;
      });

      setAllStops(stopsList);
      toast.success('Updated successfully!');
    } catch (error) {
      console.error('Error fetching stops:', error);
      toast.error('Error fetching stops.');
    }
  };

  const fetchCurrentStops = async () => {
    try {
      const busCollectionRef = collection(db, 'BusList');
      const busSnapshot = await getDocs(busCollectionRef);
      const busList = busSnapshot.docs.map((doc) => doc.data() as BusListItem);
      setCurrentStops(busList);
    } catch (error) {
      console.error('Error fetching current bus stops:', error);
      toast.error('Error fetching current bus stops.');
    }
  };

  const refreshData = async () => {
    setLoading(true);
    await Promise.all([fetchAllStops(), fetchCurrentStops()]);
    setLoading(false);
  };

  const openModal = (stop: Stop) => {
    setSelectedStop(stop);
    setEditedStop({ ...stop });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStop(null);
    setEditedStop({});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedStop((prev) => ({ ...prev, [name]: value }));
  };

  const saveChanges = async () => {
    if (selectedStop) {
      try {
        const stopDocRef = doc(db, 'azp_stop', selectedStop.id);
        await updateDoc(stopDocRef, editedStop);
        toast.success('Stop updated successfully!');
        refreshData();
        closeModal();
      } catch (error) {
        console.error('Error updating stop:', error);
        toast.error('Error updating stop.');
      }
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <div className="p-6 h-screen bg-gray-900">
      <h1 className="text-white text-4xl font-bold mb-8 text-center">Fleet Tracking</h1>

      <button
        onClick={refreshData}
        className="bg-blue-600 text-white py-2 px-6 rounded-lg mb-8 hover:bg-blue-700 ml-4"
      >
        Refresh
      </button>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-8 mt-4">
        {allStops.map((stop) => (
          <div
            key={stop.id}
            className={`p-6 rounded-xl shadow-lg transition-all duration-300 transform ${
              currentStops.some((currentStop) => currentStop.bus_stop_number === stop.bus_stop_number)
                ? 'border-4 border-blue-500 bg-gradient-to-r from-blue-900 to-black shadow-2xl scale-105 animate-pulse'
                : 'border border-gray-300 bg-gray-800 hover:shadow-xl hover:scale-105'
            }`}
          >
            <h3 className="text-xl font-semibold text-white mb-2">
              {stop.busname}
            </h3>
            <p className="text-gray-300">Stop Number: {stop.bus_stop_number}</p>
            <p className="text-gray-400">Location: {stop.location_name}</p>
            <button
              onClick={() => openModal(stop)}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg mt-4 hover:bg-blue-700"
            >
              Edit
            </button>
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Stop"
      >
        <h2 className="text-2xl font-bold mb-4">Edit Stop</h2>
        <div>
          <label className="block text-black mb-2">
            Stop Number:
            <input
              type="text"
              name="bus_stop_number"
              value={editedStop.bus_stop_number || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 bg-gray-800 border border-gray-600 text-white rounded-lg"
            />
          </label>
          <label className="block text-black mb-2">
            Location Name:
            <input
              type="text"
              name="location_name"
              value={editedStop.location_name || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 bg-gray-800 border border-gray-600 text-white rounded-lg"
            />
          </label>
          <label className="block text-black mb-2">
            Latitude:
            <input
              type="text"
              name="latitude"
              value={editedStop.latitude || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 bg-gray-800 border border-gray-600 text-white rounded-lg"
            />
          </label>
          <label className="block text-black mb-2">
            Longitude:
            <input
              type="text"
              name="longitude"
              value={editedStop.longitude || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 bg-gray-800 border border-gray-600 text-white rounded-lg"
            />
          </label>
          <label className="block text-black mb-2">
            NMEA Sentence:
            <input
              type="text"
              name="nmea_sentence"
              value={editedStop.nmea_sentence || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 bg-gray-800 border border-gray-600 text-white rounded-lg"
            />
          </label>
          <div className="flex justify-end mt-4">
            <button
              onClick={closeModal}
              className="bg-gray-600 text-white py-2 px-4 rounded-lg mr-2 hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={saveChanges}
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default BusLocation;
