import React, { useState, useEffect } from 'react';
import { db, collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from '../firebase-config'; // Import Firestore functions
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface Schedule {
  id: string; // Add ID field to handle Firestore document reference
  busNumber: string;
  departure: string;
  route: string;
  status: string;
  day: string;
}

const Dashboard: React.FC = () => {
  const [busNumber, setBusNumber] = useState('');
  const [departure, setDeparture] = useState('');
  const [route, setRoute] = useState('');
  const [status, setStatus] = useState('Available');
  const [day, setDay] = useState('Monday');
  const [busNumbers, setBusNumbers] = useState<string[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [editSchedule, setEditSchedule] = useState<Schedule | null>(null);
  const [selectedDay, setSelectedDay] = useState("");
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchBusNumbers = async () => {
      try {
        const busCollectionRef = collection(db, 'BusList');
        const busSnapshot = await getDocs(busCollectionRef);
        const busList = busSnapshot.docs.map(doc => doc.data().busname as string);
        setBusNumbers(busList);
      } catch (error) {
        console.error("Error fetching bus numbers: ", error);
      }
    };

    const fetchSchedules = async () => {
      try {
        const schedulesRef = collection(db, 'schedulebus');
        const schedulesSnapshot = await getDocs(schedulesRef);
        const schedulesList = schedulesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...(doc.data() as Omit<Schedule, 'id'>),
        }));
        setSchedules(schedulesList);
      } catch (error) {
        console.error("Error fetching schedules: ", error);
      }
    };

    fetchBusNumbers();
    fetchSchedules();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const schedulebusRef = collection(db, 'schedulebus');

      if (editSchedule) {
        // Update existing schedule
        const scheduleRef = doc(db, 'schedulebus', editSchedule.id);
        await updateDoc(scheduleRef, {
          busNumber,
          departure,
          route,
          status,
          day,
        });

        toast.success('Bus schedule updated successfully!');
        setEditSchedule(null);
      } else {
        // Add new schedule
        await addDoc(schedulebusRef, {
          busNumber,
          departure,
          route,
          status,
          day,
        });

        toast.success('Bus schedule added successfully!');
      }

      // Reset form
      setBusNumber('');
      setDeparture('');
      setRoute('');
      setStatus('Available');
      setDay('Monday');

      // Refresh schedules
      const schedulesRef = collection(db, 'schedulebus');
      const schedulesSnapshot = await getDocs(schedulesRef);
      const schedulesList = schedulesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as Omit<Schedule, 'id'>),
      }));
      setSchedules(schedulesList);
    } catch (error) {
      console.error("Error saving document: ", error);
    }
  };

  const handleEdit = (schedule: Schedule) => {
    setBusNumber(schedule.busNumber);
    setDeparture(schedule.departure);
    setRoute(schedule.route);
    setStatus(schedule.status);
    setDay(schedule.day);
    setEditSchedule(schedule);
  };
  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(e.target.value);
  };
  

  // Filter schedules by selected day
  const filteredSchedules = selectedDay
    ? schedules.filter((schedule) => schedule.day === selectedDay)
    : schedules; // If no day selected, show all

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'schedulebus', id));
      toast.success('Bus schedule deleted successfully!');

      // Refresh schedules
      const schedulesRef = collection(db, 'schedulebus');
      const schedulesSnapshot = await getDocs(schedulesRef);
      const schedulesList = schedulesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as Omit<Schedule, 'id'>),
      }));
      setSchedules(schedulesList);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };
  

  return (
    <div className="container mx-auto p-4 mt-5">
      <h1 className="text-3xl font-bold text-center text-white mb-8">Bus Schedule</h1>
      <form onSubmit={handleSubmit} className="space-y-6 max-w-lg mx-auto">
      <div>
    <label htmlFor="busNumber" className="block text-sm font-medium text-white">Bus Number</label>
    <select
      id="busNumber"
      value={busNumber}
      onChange={(e) => setBusNumber(e.target.value)}
      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      required
    >
      <option value="" disabled>Select a Bus Number</option>
      {/* Add predefined value "IIUM Shuttle" */}
      <option value="IIUM Shuttle">IIUM Shuttle Bus</option>
      {busNumbers.map((number) => (
        <option key={number} value={number}>{number}</option>
      ))}
    </select>
  </div>

        <div>
          <label htmlFor="departure" className="block text-sm font-medium text-white">Departure Time</label>
          <input
            id="departure"
            type="time"
            value={departure}
            onChange={(e) => setDeparture(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="route" className="block text-sm font-medium text-white">Route</label>
          <input
            id="route"
            type="text"
            value={route}
            onChange={(e) => setRoute(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            required
          />
        </div>

        <div>
          <label htmlFor="status" className="block text-sm font-medium text-white">Status</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
          >
            <option value="Available">Available</option>
            <option value="At Hub">At Hub</option>
          </select>
        </div>

        <div>
          <label htmlFor="day" className="block text-sm font-medium text-white">Day</label>
          <select
            id="day"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            required
          >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md shadow-md hover:bg-blue-600 transition duration-300"
        >
          {editSchedule ? 'Update Schedule' : 'Add Schedule'}
        </button>
      </form>

      <div className="mt-8">
      <h2 className="text-2xl font-bold text-white mb-4">Schedules</h2>

      {/* Day Picker */}
      <div className="mb-4">
        <label htmlFor="dayPicker" className="text-white mr-2">
          Select Day:
        </label>
        <select
          id="dayPicker"
          className="p-2 bg-gray-800 text-white rounded-md"
          value={selectedDay}
          onChange={handleDayChange}
        >
          <option value="">All Days</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Bus Number</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Departure Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Route</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Day</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-gray-700 divide-y divide-gray-600">
            {filteredSchedules.map((schedule) => (
              <tr key={schedule.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                  {schedule.busNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {schedule.departure}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {schedule.route}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {schedule.status}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {schedule.day}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  <button
                    onClick={() => handleEdit(schedule)}
                    className="bg-yellow-500 text-white p-2 rounded-md shadow-md hover:bg-yellow-600 transition duration-300 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(schedule.id)}
                    className="bg-red-500 text-white p-2 rounded-md shadow-md hover:bg-red-600 transition duration-300"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
    </div>
   );
};

export default Dashboard;
