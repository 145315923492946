import React, { useEffect, useState } from 'react';
import { collection, getDocs } from '../firebase-config';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { db } from "../firebase-config";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const Peakhours: React.FC = () => {
  const [busScheduleData, setBusScheduleData] = useState<Map<string, string[]>>(new Map());
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
  const [daysOfWeek] = useState<string[]>(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  // Function to convert time to 24-hour format
  const convertTo24HourFormat = (time: string) => {
    const [timePart, modifier] = time.split(' ');  // Split into time and AM/PM part
    let [hours, minutes] = timePart.split(':').map(Number); // Split time into hours and minutes

    if (modifier === 'PM' && hours < 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0; // Convert 12 AM to 00

    // Return time in 24-hour format as a string
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const fetchBusSchedules = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const scheduleMap = new Map<string, string[]>();

      usersSnapshot.forEach((doc) => {
        const data = doc.data();
        const busSchedule = data.busSchedule as { [key: string]: string[] } | undefined;

        if (busSchedule) {
          for (const [day, times] of Object.entries(busSchedule)) {
            if (Array.isArray(times)) {
              if (scheduleMap.has(day)) {
                const existingTimes = scheduleMap.get(day) || [];
                scheduleMap.set(day, [...existingTimes, ...times]);
              } else {
                scheduleMap.set(day, times);
              }
            }
          }
        }
      });

      daysOfWeek.forEach(day => {
        if (!scheduleMap.has(day)) {
          scheduleMap.set(day, []);
        }
      });

      setBusScheduleData(scheduleMap);

    } catch (error) {
      console.error('Error fetching bus schedules:', error);
    }
  };

  useEffect(() => {
    fetchBusSchedules(); 
    const intervalId = setInterval(fetchBusSchedules, 10000); 

    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    if (selectedDay) {
      const selectedSchedule = busScheduleData.get(selectedDay) || [];
      
      // Explicitly declare that the object has string keys and number values
      const timeCounts: Record<string, number> = selectedSchedule.reduce((acc: Record<string, number>, time: string) => {
        const formattedTime = convertTo24HourFormat(time); // Convert every time to 24-hour format
        acc[formattedTime] = (acc[formattedTime] || 0) + 1; // Increment count for the time
        return acc;
      }, {}); // Initialize as an empty object
      
      // Sorted labels in 24-hour format
      const chartLabels = Object.keys(timeCounts).sort(); // Sort times in ascending order
      
      // Ensure the data aligns with sorted labels
      const chartValues = chartLabels.map((time) => timeCounts[time] || 0); // Fetch corresponding counts
      
      setChartData({
        labels: chartLabels, // Correctly ordered labels
        datasets: [
          {
            label: `Bus Times on ${selectedDay}`,
            data: chartValues, // Aligned data
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      });
  
      console.log('Chart Labels:', chartLabels); // Debugging: Check if all labels are correct
      console.log('Chart Values:', chartValues); // Debugging: Check if all values are correct
    }
  }, [selectedDay, busScheduleData]);
  
  

  return (
    <div className="p-6 h-full">
      <h1 className="text-white text-3xl font-bold mb-4">Peak Hours Tracker</h1>
      <div className="p-4 bg-gray-100 shadow-md rounded-lg mb-4">
        <select
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="">Select a day</option>
          {daysOfWeek.map(day => (
            <option key={day} value={day}>{day}</option>
          ))}
        </select>
        {selectedDay && (
          <div className="mt-4 mb-10">
            <Bar data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Peakhours;
