import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from '../firebase-config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';

interface Event {
  id: string;
  title: string;
  desc: string;
  fimg: string;
  url: string;
}

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Omit<Event, 'id'>>({
    title: '',
    desc: '',
    fimg: '',
    url: ''
  });
  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollectionRef = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollectionRef);
        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Event[];
        setEvents(eventsList);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = async () => {
    try {
      await addDoc(collection(db, 'events'), formData);
      toast.success('Event added successfully!');
      setFormData({ title: '', desc: '', fimg: '', url: '' });
      setLoading(true);
      setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error adding event:', error);
      toast.error('Error adding event.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (editingEvent) {
      try {
        const eventDocRef = doc(db, 'events', editingEvent.id);
        await updateDoc(eventDocRef, formData);
        toast.success('Event updated successfully!');
        setEditingEvent(null);
        setFormData({ title: '', desc: '', fimg: '', url: '' });
        setLoading(true);
        setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
      } catch (error) {
        console.error('Error updating event:', error);
        toast.error('Error updating event.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (event: Event) => {
    setEditingEvent(event);
    setFormData({
      title: event.title,
      desc: event.desc,
      fimg: event.fimg,
      url: event.url
    });
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      toast.success('Event deleted successfully!');
      setLoading(true);
      setTimeout(() => window.location.reload(), 1500); // Refresh page after 1.5 seconds
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <h1 className="text-white text-3xl font-bold mb-6">Event Management</h1>
      <div className="mb-6">
        <h2 className="text-white text-xl font-semibold mb-2">{editingEvent ? 'Edit Event' : 'Add New Event'}</h2>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Title"
          className="border p-2 mb-2 w-full"
        />
        <textarea
          name="desc"
          value={formData.desc}
          onChange={handleChange}
          placeholder="Description"
          className="border p-2 mb-2 w-full"
        />
        <input
          type="text"
          name="fimg"
          value={formData.fimg}
          onChange={handleChange}
          placeholder="Image URL"
          className="border p-2 mb-2 w-full"
        />
        <input
          type="text"
          name="url"
          value={formData.url}
          onChange={handleChange}
          placeholder="URL"
          className="border p-2 mb-2 w-full"
        />
        <button
          onClick={editingEvent ? handleUpdate : handleAdd}
          className="bg-blue-500 text-white p-2 rounded"
        >
          {editingEvent ? 'Update Event' : 'Add Event'}
        </button>
      </div>
      {loading ? (
        <p className='text-white'>Loading events...</p>
      ) : (
        <div className="flex flex-wrap gap-6">
          {events.map((event) => (
            <div key={event.id} className="p-6 bg-white shadow-md rounded-md max-w-xs ">
              <h2 className="text-lg font-semibold mb-2">{event.title}</h2>
              <img src={event.fimg} alt={event.title} className="mb-2 rounded-md w-full h-24 object-cover" />
              <p className="text-sm">{event.desc}</p>
              <a href={event.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">View</a>
              <div className="mt-2">
                <button
                  onClick={() => handleEdit(event)}
                  className="bg-yellow-500 text-white p-2 rounded mr-2 text-sm"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(event.id)}
                  className="bg-red-500 text-white p-2 rounded text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Events;
