import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Reports from './pages/Reports';
import Ads from './pages/Ads';
import Events from './pages/Event';
import News from './pages/News';
import BusLocation from './pages/BusLocation';
import './App.css';
import BusReport from './pages/BusReport';
import Peakhours from './pages/PeakHours';
import BusSchedule from './pages/Schedule';
import ShowSchedules from './pages/ShowSchedule';
import UserList from './pages/Users';
import LecturersPage from './pages/GoAttendLec';
import AssignUsersPage from './pages/GoAttendSec';
import LoginPage from './pages/Login';
import { AuthProvider } from './context/usercontext'; // Import AuthProvider

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

// Main Layout for routes other than Login
const AppLayout: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className="flex h-screen relative">
      {/* Burger Icon to toggle Sidebar */}
      <button
  onClick={toggleSidebar}
  className="absolute top-4 left-4 z-50 text-white p-2 bg-gray-800 rounded-md hover:bg-gray-700"
>
  ☰
</button>



      {/* Sidebar, only visible when isSidebarVisible is true */}
      {isSidebarVisible && <Sidebar />}

      <div className={`flex-1 h-screen bg-gray-900 ${isSidebarVisible ? 'pl-0' : 'pl-0'} transition-all`}>
        <div className="flex-1 p-6 overflow-y-auto relative">
          <div className="bg-gray-900 h-full w-full z-[-1] top-0 left-0 absolute">
            <div className="absolute z-[-1]">
              <img
                src="https://tronova.azmiproductions.com/smartshuttle/assets/headerbus.jpg"
                alt="Background"
                className="w-screen h-full object-cover opacity-20" 
              />
            </div>
          </div>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/bus" element={<BusLocation />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/events" element={<Events />} />
            <Route path="/news" element={<News />} />
            <Route path="/bus-reports" element={<BusReport />} />
            <Route path="/ads" element={<Ads />} />
            <Route path="/peak" element={<Peakhours />} />
            <Route path="/schedule" element={<BusSchedule />} />
            <Route path="/showschedule" element={<ShowSchedules />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/go-lecturer" element={<LecturersPage />} />
            <Route path="/go-section" element={<AssignUsersPage />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

// Main App component
const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<AppLayout />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
