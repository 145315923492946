import React, { useEffect, useState } from 'react';
import { db, collection, getDocs } from '../firebase-config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext'; // Import Firestore functions

interface Schedule {
  busNumber: string;
  departure: string;
  route: string;
  status: string;
  day: string; // Add day field
}

const ShowSchedules: React.FC = () => {
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const schedulesRef = collection(db, 'schedulebus');
        const schedulesSnapshot = await getDocs(schedulesRef);
        const schedulesList = schedulesSnapshot.docs.map(doc => ({
          ...(doc.data() as Omit<Schedule, 'day'>), // Exclude the 'day' field for type safety
          day: doc.data().day as string, // Explicitly include the 'day' field
        }));
        setSchedules(schedulesList);
      } catch (error) {
        console.error("Error fetching schedules: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchedules();
  }, []);

  return (
    <div className="container mx-auto p-4 mt-5">
      <h1 className="text-3xl font-bold text-center text-white mb-8">Bus Schedules</h1>
      {loading ? (
        <p className="text-white text-center">Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-800 text-white">
              <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Day</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Bus Number</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Departure</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Route</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Status</th>
                 {/* Add day column */}
              </tr>
            </thead>
            <tbody className="bg-gray-800 divide-y divide-gray-600">
              {schedules.map((schedule, index) => (
                <tr key={index}>
                    <td className="px-6 py-4 text-sm text-white">{schedule.day}</td>
                  <td className="px-6 py-4 text-sm font-medium text-white">{schedule.busNumber}</td>
                  <td className="px-6 py-4 text-sm text-white">{schedule.departure}</td>
                  <td className="px-6 py-4 text-sm text-white">{schedule.route}</td>
                  <td className="px-6 py-4 text-sm text-white">{schedule.status}</td>
                   {/* Display day */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ShowSchedules;
