import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/usercontext';
import {
  collection,
  getDocs,
  deleteDoc,
  doc
} from 'firebase/firestore';

interface User {
  id: string;
  imageURL: string;
  matricNo: string;
  name: string;
  personalURL: string;
  username: string;
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 6;
  const [searchTerm, setSearchTerm] = useState('');
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    
    if (!isAuthenticated) {
      navigate('/login'); 
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const userCollection = collection(db, 'users'); // Change to your collection name
        const userSnapshot = await getDocs(userCollection);
        const userList = userSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as User[];
        setUsers(userList);
        setFilteredUsers(userList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Handle deleting users
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'users', id));
      setUsers(users.filter(user => user.id !== id));
      setFilteredUsers(filteredUsers.filter(user => user.id !== id));
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  // Handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredUsers(
      users.filter(
        user =>
          (user.name?.toLowerCase() || "").includes(searchValue) ||
          (user.matricNo?.toLowerCase() || "").includes(searchValue)
      )
    );
  };
  
  // Pagination logic
  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const currentUsers = filteredUsers.slice(firstUserIndex, lastUserIndex);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl text-white font-bold mb-2 text-center">User Management</h1>
      
      {/* User Count */}
      <p className="text-center text-white mb-4">Total Users: {users.length}</p>

      {/* Search Bar */}
      <div className="flex justify-center mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full max-w-md p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Search by name or matric number..."
        />
      </div>

      {/* User Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentUsers.map((user) => (
          <div key={user.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="flex items-center p-6">
            <img
  src={user.imageURL || 
       (user.username 
        ? `https://smartcard.iium.edu.my/packages/card/printing/camera/uploads/original/${user.username}.jpeg` 
        : 'https://media.tenor.com/dBJslgobx3oAAAAM/glitch-unknown.gif')}
  alt={user.name}
  className="w-24 h-24 rounded-full object-cover border-2 border-gray-300"
/>


              <div className="ml-6">
                <h2 className="text-xl font-semibold text-gray-900">{user.name}</h2>
                <p className="text-gray-500">Matric No: {user.matricNo}</p>
                <p className="text-gray-500">Username: {user.username}</p>
                <a
                  href={user.personalURL}
                  className="text-blue-500 hover:underline mt-2 block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Profile Link : {user.personalURL}
                </a>
                <button
                  onClick={() => handleDelete(user.id)}
                  className="mt-3 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <button
          onClick={handlePreviousPage}
          className="mx-2 px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          className="mx-2 px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserList;
